<template>
    <div class="regionalManagement" v-loading="loading">
        <header>
            <p class="el_left">
                区域管理
            </p>
        </header>
        <div class="regionContent">
            <div class="region">
                <h5>
                    区域
                    <el-button
                        style="font-size: 0.18rem; line-height:0.36rem; margin-right:0.15rem; padding:0;"
                        class="iconfont icontianjia1 el_right"
                        type="text"
                        title="添加"
                        @click="append"
                    >
                    </el-button>
                </h5>
                <el-tree
                    :data="regionData"
                    :props="defaultPropsRegion"
                    default-expand-all
                    :render-content="renderContent"
                    :expand-on-click-node="false"
                    highlight-current
                    @node-click="selectRegion"
                ></el-tree>
            </div>
            <div>
                <h5>部门</h5>
                <el-tree
                    :data="department"
                    :props="defaultPropsDepartment"
                    default-expand-all
                    highlight-current
                    @node-click="selectDepartment"
                ></el-tree>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // 销售价格管理-区域管理弹出层
    name: 'regional-management',
    components: {},
    props: {},
    data() {
        return {
            // 区域列表数据
            regionData: [],
            // 区域tree展示数据节点名称
            defaultPropsRegion: {
                children: 'orgs',
                label: 'name',
            },
            // 当前选中区域
            currentRegion: '',
            // 部门数据
            department: [],
            // 部门tree展示数据节点名称
            defaultPropsDepartment: {
                children: 'children',
                label: 'Dept_Name',
            },
            loading: false,
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 拉取区域列表
        gitRegionData() {
            this.$axios
                .get('/interfaceApi/sale/price_config/get_area_list')
                .then(res => {
                    this.regionData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 区域扩展按钮
        renderContent(h, { node, data }) {
            if (data.orgs) {
                return (
                    <span style="flex: 1; display: flex; align-items: center; justify-content: space-between; font-size: 14px; padding-right: 8px;">
                        <span>
                            <span>{node.label}</span>
                        </span>
                        <span>
                            <el-button
                                style="font-size: 0.14rem;"
                                class="iconfont iconbianji"
                                type="text"
                                title="修改"
                                on-click={ () => this.modify(data) }
                            ></el-button>
                            <el-button
                                style="font-size: 0.14rem;"
                                class="iconfont iconshanchu"
                                type="text"
                                title="删除"
                                on-click={ () => this.remove(node, data) }
                            ></el-button>
                        </span>
                    </span>);
            }
            return (
                <span style="flex: 1; display: flex; align-items: center; justify-content: space-between; font-size: 14px; padding-right: 8px;">
                    <span>
                        <span>{node.label}</span>
                    </span>
                    <span>
                        <el-button
                            style="font-size: 0.14rem;"
                            class="iconfont iconshanchu"
                            type="text"
                            title="删除"
                            on-click={ () => this.remove(node, data) }
                        ></el-button>
                    </span>
                </span>);


        },
        // 区域tree修改节点名称
        modify(data) {
            this.$prompt('请输入新的区域名称', '修改区域名称', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                data.name = value;
                this.modificationRequest(data);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消修改',
                });
            });
        },
        // 区域tree添加节点
        append() {
            this.$prompt('请输入区域名称', '添加区域', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                const data = {
                    id: '',
                    name: value,
                    orgs: [],
                };
                this.loading = true;
                this.$axios
                    .post('/interfaceApi/sale/price_config/add_area', data)
                    .then(res => {
                        if (res) {
                            this.$message({
                                message: res,
                                type: 'success',
                            });
                            this.regionData.push(data);
                            this.loading = false;
                        // data.orgs.push(newChild);
                        // this.gitRegionData();
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$message.error(error.ErrorCode.Message);
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消添加',
                });
                this.loading = false;
            });
        },
        // 区域tree删除节点
        remove(node, data) {
            this.loading = true;
            if (data.orgs) {
                const parent = node.parent;
                const orgs = parent.data.orgs || parent.data;
                const index = orgs.findIndex(d => d.id === data.id);
                this.$axios
                    .put('/interfaceApi/sale/price_config/modify_area/' + data.id)
                    .then(res => {
                        if (res) {
                            this.$message({
                                message: res,
                                type: 'success',
                            });
                            orgs.splice(index, 1);
                            this.loading = false;
                            this.gitDepartment();
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                let regionData = '';
                for (let j = 0; j < this.regionData.length; j++) {
                    for (let k = 0; k < this.regionData[j].orgs.length; k++) {
                        if (this.regionData[j].orgs[k].id === data.id) {
                            this.regionData[j].orgs.splice(k, 1);
                            regionData = this.regionData[j];
                        }
                    }
                }
                if (regionData === '') {return;}
                this.$axios
                    .put('/interfaceApi/sale/price_config/modify_area/', regionData)
                    .then(res => {
                        if (res) {
                            this.$message({
                                message: res,
                                type: 'success',
                            });
                            this.gitDepartment();
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 修改请求
        modificationRequest(data) {
            this.$axios
                .put('/interfaceApi/sale/price_config/modify_area', data)
                .then(res => {
                    if (res) {
                        this.$message({
                            message: res,
                            type: 'success',
                        });
                        // data.orgs.push(newChild);
                        // this.gitRegionData();
                        this.gitDepartment();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取部门
        gitDepartment() {
            this.$axios
                .get('/interfaceApi/basicplatform/depts?page=1&rows=999')
                .then(res => {
                    // 过滤已选择部门
                    for (let i = res.List.length - 1; i > 0; i--) {
                        for (let j = 0; j < this.regionData.length; j++) {
                            for (let k = 0; k < this.regionData[j].orgs.length; k++) {
                                if (res.List[i] && this.regionData[j].orgs[k].id === res.List[i].Dept_Id) {
                                    res.List.splice(i, 1);
                                }
                            }
                        }
                    }
                    this.processingData(res.List);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 处理部门数据
        processingData(data) {
            const map = {};
            const val = [];
            data.forEach(it => {
                map[it.Dept_Id] = it;
            });
            data.forEach(it => {
                const parent = map[it.Parent_Id];
                if (parent) {
                    if (!Array.isArray(parent.children)) {parent.children = [];}
                    parent.children.push(it);
                } else {
                    val.push(it);
                }
            });
            this.department = val;
        },
        // 选择区域
        selectRegion(data) {
            this.currentRegion = data;
        },
        // 选择部门,添加到区域
        selectDepartment(data) {
            if (this.currentRegion !== '') {
                if (this.currentRegion.orgs) {
                    this.currentRegion.orgs.push({
                        id: data.Dept_Id,
                        name: data.Dept_Name,
                    });
                    this.modificationRequest(this.currentRegion);
                } else {
                    this.$message({
                        message: '请选择区域,当前选中部门！',
                        type: 'warning',
                    });
                }
            } else {
                this.$message({
                    message: '请选择区域!',
                    type: 'warning',
                });
            }
        },
    },
    created() {},
    mounted() {
        // 获取区域数据
        this.gitRegionData();
        // 获取部门数据
        this.gitDepartment();
    },
};
</script>
<style lang="stylus" scoped>
.regionalManagement
    height 100%
    header
        padding-right 0.6rem
        height 0.6rem
        background #6A7EBE
        p
            font-size 0.18rem
            line-height 0.6rem
            text-indent 0.24rem
            text-align left
            color #fff
    .regionContent
        width 100%
        height calc(100% - 0.6rem)
        padding 0.2rem
        overflow hidden
        >div
            width calc((100% - 20px)/2)
            height 100%
            overflow hidden
            margin-right 0.2rem
            border 1px solid #D7D7D7
            float left
            &:last-child
                margin-right 0
            h5
                height 0.36rem
                background #ECF1F6
                font-size 0.16rem
                line-height 0.36rem
                text-indent 0.2rem
            .el-tree
                padding 0.1rem 0.2rem
                width 100%
                height calc(100% - 0.36rem)
                overflow-y auto
                .el-tree-node__content
                    height 0.36rem

</style>